export const ga4Events = {
    'click-view-schedules-navigation': {
        label: 'View Schedules',
        name: 'Click_View Schedules_Banner',
        short_name: 'cli_viesch_ban',
        action: 'Click',
        page_section : 'Banner'
    },
    'click-contact-course-advisor-banner': {
        label: 'Contact Course Advisor',
        name: 'Click_Contact Course Advisor_Banner',
        short_name: 'cli_concouadv_ban',
        action: 'Click',
        page_section : 'Banner'
    },  
    'click-refer-and-earn-banner': {
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_Banner', 
        short_name: 'cli_refandear_ban',   
        action: 'Click',
        page_section : 'Banner'
    },  
    'click-share-whatsapp-banner': {
        label: 'Share_Whatsapp',
        name: 'Click_Share_Whatsapp_Banner', 
        short_name: 'cli_sha_wha_ban',   
        action: 'Click',
        page_section : 'Banner'
    }, 
    'click-share-linkedIn-banner': {
        label: 'Share_Linkedin',
        name: 'Click_Share_Linkedin_Banner', 
        short_name: 'cli_sha_lin_ban', 
        action: 'Click',
        page_section : 'Banner'
    },
    'click-share-facebook-banner': {
        label: 'Share_Facebook',
        name: 'Click_Share_Facebook_Banner', 
        short_name: 'cli_sha_fac_ban',
        action: 'Click',
        page_section : 'Banner'
    },
    'click-share-twitter-banner': {
        label: 'Share_Twitter',
        name: 'Click_Share_Twitter_Banner', 
        short_name: 'cli_sha_twi_ban',
        action: 'Click',
        page_section : 'Banner'
    },
    'click-overview-1-page-top-navigation': {
        label: 'Overview',
        name: 'Click_Overview_Page Top Navigation', 
        short_name: 'cli_ove_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-overview-2-page-top-navigation': {
        label: 'Overview',
        name: 'Click_Overview_Page Top Navigation', 
        short_name: 'cli_ove_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-overview-3-page-top-navigation': {
        label: 'Overview',
        name: 'Click_Overview_Page Top Navigation', 
        short_name: 'cli_ove_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },                   
    'click-overview-4-page-top-navigation': {
        label: 'Overview',
        name: 'Click_Overview_Page Top Navigation', 
        short_name: 'cli_ove_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-who-should-attend-1-page-top-navigation': {
        label: 'Who Should attend',
        name: 'Click_Who Should attend_Page Top Navigation', 
        short_name: 'cli_whoshoatt_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },    
    'click-who-should-attend-2-page-top-navigation': {
        label: 'Who Should attend',
        name: 'Click_Who Should attend_Page Top Navigation', 
        short_name: 'cli_whoshoatt_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-certification-process-1-page-top-navigation': {
        label: 'Certification Process',
        name: 'Click_Certification Process_Page Top Navigation', 
        short_name: 'cli_cerpro_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },  
    'click-project-1-page-top-navigation': {
        label: 'Project',
        name: 'Click_Project_Page Top Navigation', 
        short_name: 'cli_pro_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },  
    'click-seo-unique-content-1-page-top-navigation': {
        label: 'Seo Unique content',
        name: 'Click_Seo Unique content_Page Top Navigation', 
        short_name: 'cli_seounicon_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },   
    'click-process-steps-1-page-top-navigation': {
        label: 'Process Steps',
        name: 'Click_Process Steps_Page Top Navigation', 
        short_name: 'cli_proste_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    }, 
    'click-training-moments-1-page-top-navigation': {
        label: 'Training Moments',
        name: 'Click_Training Moments_Page Top Navigation', 
        short_name: 'cli_tramom_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-what-you-will-learn-1-page-top-navigation': {
        label: 'What You Will Learn',
        name: 'Click_What You Will Learn_Page Top Navigation', 
        short_name: 'cli_whayouwilllea_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    }, 
    'click-what-you-will-learn-2-page-top-navigation': {
        label: 'What You Will Learn',
        name: 'Click_What You Will Learn_Page Top Navigation', 
        short_name: 'cli_whayouwilllea_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    }, 
    'click-trainer-1-page-top-navigation': {
        label: 'Trainer',
        name: 'Click_Trainer_Page Top Navigation', 
        short_name: 'cli_tra_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-review-1-page-top-navigation': {
        label: 'Reviews',
        name: 'Click_Reviews_Page Top Navigation', 
        short_name: 'cli_rev_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },                            
    'click-prerequisite-1-page-top-navigation': {
        label: 'Prerequisites',
        name: 'Click_Prerequisites_Page Top Navigation', 
        short_name: 'cli_pre_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-knowledgehut-experience-1-page-top-navigation': {
        label: 'Why KH',
        name: 'Click_Why KH_Page Top Navigation', 
        short_name: 'cli_whykh_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-knowledgehut-experience-2-page-top-navigation': {
        label: 'Why KH',
        name: 'Click_Why KH_Page Top Navigation', 
        short_name: 'cli_whykh_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-curriculum-1-page-top-navigation': {
        label: 'Curriculum',
        name: 'Click_Curriculum_Page Top Navigation', 
        short_name: 'cli_cur_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-career-path-1-page-top-navigation': {
        label: 'Career Path',
        name: 'Click_Career Path_Page Top Navigation', 
        short_name: 'cli_carpat_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },  
    'click-faq-1-page-top-navigation': {
        label: 'FAQs',
        name: 'Click_FAQs_Page Top Navigation', 
        short_name: 'cli_faq_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-take2-1-page-top-navigation': {
        label: 'Take 2',
        name: 'Click_Take 2_Page Top Navigation', 
        short_name: 'click_tak2_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-corporate-group-registration-1-page-top-navigation': {
        label: 'Corporate Group Registration',
        name: 'Click_Corporate Group Registration_Page Top Navigation', 
        short_name: 'click_corgroreg_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },     
    'click-for-enterprise-page-top-navigation': {
        label: 'For Enterprise',
        name: 'Click_For Enterprise _Page Top Navigation', 
        short_name: 'cli_forent_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-download-syllabus-page-top-navigation': {
        label: 'Download Syllabus',
        name: 'Click_Download Syllabus_Page Top Navigation', 
        short_name: 'cli_dowsyl_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'click-curriculum-expand': {
        label: 'Curriculum_Expand',
        name: 'Click_Curriculum_Expand_Curriculum', 
        short_name: 'cli_cur_exp_cur',
        action: 'Click',
        page_section : 'Curriculum'
    },
    'click-view-schedule-and-pricing': {
        label: 'View Schedule and Pricing',
        name: 'Click_View Schedule and Pricing_Curriculum', 
        short_name: 'cli_vieschandpri_cur',
        action: 'Click',
        page_section : 'Curriculum'
    },
    'click-talk-to-scrum-career-path': {
        label: 'Talk to Scrum Expert',
        name: 'Click_Talk to Scrum Expert_For Enterprise', 
        short_name: 'cli_taltoscrexp_forent',
        action: 'Click',
        page_section : 'For Enterprise'
    },
    'click-show-discount-career-path': {
        label: 'Show Discount',
        name: 'Click_Show Discount_For Enterprise', 
        short_name: 'cli_shodis_forent',
        action: 'Click',
        page_section : 'For Enterprise'
    },
    'click-enroll-your-group-career-path': {
        label: 'Enroll your Group Now',
        name: 'Click_Enroll your Group Now_For Enterprise', 
        short_name: 'cli_enryougronow_forent',
        action: 'Click',
        page_section : 'For Enterprise'
    },
    'click-faq-expand': {
        label: 'FAQs_Expand',
        name: 'Click_FAQs_Expand_FAQs', 
        short_name: 'cli_faq_exp_faq',
        action: 'Click',
        page_section : 'FAQs'
    },
    'click-seo-faq-expand': {
        label: 'SEO FAQs_Expand',
        name: 'Click_SEO FAQs_Expand_FAQs', 
        short_name: 'cli_seofaq_exp_faq',
        action: 'Click',
        page_section : 'SEO FAQs'
    },    
    'click-read-more-testimonals-expand': {
        label: 'Read More Testimonials_Expand',
        name: 'Click_Read More Testimonials_Expand Reviews', 
        short_name: 'cli_reamortes_exp_faq',
        action: 'Click',
        page_section : 'Reviews'
    },
    'click-view-schedules-learning-type-1-sticky-nav-rhs': {
        label: 'View Online Schedules',
        name: 'Click_View Online Schedules_Sticky Nav RHS', 
        short_name: 'cli_vieonlsch_stinavrhs',
        action: 'Click',
        page_section : 'Sticky Nav RHS'
    },
    'click-view-schedules-learning-type-2-sticky-nav-rhs': {
        label: 'View Classroom Schedules',
        name: 'Click_View Classroom Schedules_Sticky Nav RHS', 
        short_name: 'cli_vieclssch_stinavrhs',
        action: 'Click',
        page_section : 'Sticky Nav RHS'
    }, 
    'click-view-schedules-learning-type-3-sticky-nav-rhs': {
        label: 'Enrol Now',
        name: 'Click_OSL Enrol Now_Sticky Nav RHS', 
        short_name: 'cli_oslenrnow_stinavrhs',
        action: 'Click',
        page_section : 'Sticky Nav RHS'
    },       
    'click-request-quote-corporate-trainig-sticky-nav-rhs': {
        label: 'Request Quote_Corporate Training',
        name: 'Click_Request Quote_Corporate Training_Sticky Nav RHS', 
        short_name: 'cli_reqquo_cortra_stinavrhs',
        action: 'Click',
        page_section : 'Sticky Nav RHS'
    },
    'submit-for-enterprise-page-top-navigation': {
        label: 'For Enterprise',
        name: 'Submit_For Enterprise_Page Top Navigation', 
        short_name: 'sub_forent_pagtopnav',
        action: 'Submit',
        page_section : 'Page Top Navigation'  
    },
    'submit-contact-course-advisor-banner': {
        label: 'Contact Course Advisor',
        name: 'Submit_Contact Course Advisor_Banner', 
        short_name: 'sub_concouadv_ban',
        action: 'Submit',
        page_section : 'Banner'
    },
    'submit-download-syllabus-page-top-navigation': {
        label: 'Download Syllabus',
        name: 'Submit_Download Syllabus_Page Top Navigation', 
        short_name: 'sub_dowsyl_pagtopnav',
        action: 'Submit',
        page_section : 'Page Top Navigation'
    },
    'submit-submit-talk-to-scrum-expert-career-path': {
        label: 'Submit_Talk to Scrum Expert',
        name: 'Submit_Submit_Talk to Scrum Expert_For Enterprise', 
        short_name: 'sub_sub_taltoscrexp_forent',
        action: 'Submit',
        page_section : 'For Enterprise'
    },
    'submit-request-quote-corporate-training-sticky-nav-rhs': {
        label: 'Request Quote_Corporate Training',
        name: 'Submit_Request Quote_Corporate Training_Sticky Nav RHS', 
        short_name: 'sub_reqquo_cortra_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'submit-request-more-info-individuals-sticky-nav-rhs': {
        label: 'Request More info_Individuals',
        name: 'Submit_Request More info_Individuals_Sticky Nav RHS', 
        short_name: 'sub_reqmorinf_ind_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'submit-request-more-info-business-sticky-nav-rhs': {
        label: 'Request More info_Business',
        name: 'Submit_Request More info_Business_Sticky Nav RHS', 
        short_name: 'sub_reqmorinf_bus_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'submit-weekly-newsletter-bottom-nav': {
        label: 'Weekly Newsletter',
        name: 'Submit_Weekly Newsletter_Bottom Nav', 
        short_name: 'sub_weenew_botnav',
        action: 'Submit',
        page_section : 'Bottom Nav'
    },
    'click-corporate-training-banner': {
        label: 'Corporate Training',
        name: 'Click_Corporate Training_Banner', 
        short_name: 'click_cortra_Ban',
        action: 'Click',
        page_section : 'Banner'
    },
    'submit-request-a-quote-banner': {
        label: 'Request a Quote',
        name: 'Submit_Request a Quote_Banner', 
        short_name: 'submit_reqaquo_ban',
        action: 'Submit',
        page_section : 'Banner'
    },
    'click-view-schedules-sticky-nav': {
        label: 'View Schedules',
        name: 'Click_View Schedules_Sticky Nav', 
        short_name: 'click_viesche_stinav',
        action: 'Click',
        page_section : 'Sticky Nav'
    },
    'click-request-quote-for-corporates-sticky-nav': {
        label: 'Request Quote_For corporates',
        name: 'Click_Request Quote_For Corporates_Page Stikcy RHS', 
        short_name: 'cli_forcor_pagstirhs',
        action: 'Click',
        page_section : 'Page Stikcy RHS'
    },
    'submit-request-quote-for-corporates-sticky-nav': {
        label: 'Request Quote_For Corporates',
        name: 'Submit_Request Quote_For Corporates_Page Stikcy RHS', 
        short_name: 'sub_forcor_pagstirhs',
        action: 'Submit',
        page_section : 'Page Stikcy RHS'
    },
    'click-course-advisor-footer-sticky': {
        label: 'Course advisor',
        name: 'Click_Course advisor_Footer Sticky', 
        short_name: 'click_couadv_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'submit-course-advisor-footer-sticky': {
        label: 'Course advisor',
        name: 'Submit_Course advisor_Footer Sticky', 
        short_name: 'submit_couadv_foost',
        action: 'Submit',
        page_section : 'Footer Sticky'
    },
    'click-join-whatsapp-group-success-popup': {
        label: 'Join Now',
        name: 'Click_Join Whatsapp Group_Success Popup', 
        short_name: 'cli-joiwhagro_sucpop',
        action: 'Click',
        page_section : 'Success Popup'
    },
    'submit-drop-a-query-cta1': {
        label: 'Drop A Query',
        name: 'Submit_Drop A Query_CTA 1', 
        short_name: 'sub_droaquecta1_cta1',
        action: 'Submit',
        page_section : 'CTA 1'
    },
    'submit-request-a-quote-cta1': {
        label: 'Request A Quote',
        name: 'Submit_Request A Quote_CTA 1', 
        short_name: 'sub_reqaquocta1_cta1',
        action: 'Submit',
        page_section : 'CTA 1'
    },
    'submit-download-syllabus-cta1': {
        label: 'Download Syllabus',
        name: 'Submit_Download Syllabus_CTA 1', 
        short_name: 'sub_dowsylcta1_cta1',
        action: 'Submit',
        page_section : 'CTA 1'
    },
    'submit-contact-learning-advisor-cta1': {
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_CTA 1', 
        short_name: 'submit_conleadv_cta1',
        action: 'Submit',
        page_section : 'CTA 1'
    },
    'submit-request-for-call-back-cta1': {
        label: 'Request For Call Back',
        name: 'Submit_Request For Call Back_CTA 1', 
        short_name: 'submit_reqforcallbac_cta1',
        action: 'Submit',
        page_section : 'CTA 1'
    },
    'click-cta1-link': {
        label: 'CTA 1',
        name: 'Click_CTA 1', 
        short_name: 'cli_cta1',
        action: 'Click',
        page_section : 'CTA 1'
    },     
    'click-cta2-link': {
        label: 'CTA 2',
        name: 'Click_CTA 2', 
        short_name: 'cli_cta2',
        action: 'Click',
        page_section : 'CTA 2'
    }, 
    'submit-contact-course-advisor-banner-rhs': {
        label: 'Contact Course Advisor',
        name: 'Submit_Contact Course Advisor_Banner RHS', 
        short_name: 'sub_concouadv_banrhs',
        action: 'Submit',
        page_section : 'Banner RHS'
    }, 
    'submit-drop-a-query-footer-sticky': {
        label: 'Drop A Query',
        name: 'Submit_Drop A Query_Footer Sticky', 
        short_name: 'submit_droaque_foosti',
        action: 'Submit',
        page_section : 'Footer Sticky'
    }, 
    'submit-request-a-call-footer-sticky': { //done
        label: 'Request A Call',
        name: 'Submit_Request A Call_Footer Sticky', 
        short_name: 'sub_reqacall_fotsti',
        action: 'Submit',
        page_section : 'Footer Sticky'
    }, 
    'click-drop-a-query-footer-sticky': {
        label: 'Drop A Query',
        name: 'Click_Drop A Query_Footer Sticky', 
        short_name: 'click_droaque_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    }, 
    'click-request-a-call-footer-sticky': {
        label: 'Request A Call',
        name: 'Click_Request A Call_Footer Sticky', 
        short_name: 'cli_reqacall_fotsti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },

     // layout d new click events

     'layout-d-click-refer-and-earn-banner1': { //done
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_Banner1',
        short_name: 'cli_refandear_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-click-get-started-for-free-banner1': { 
        label: 'Get Started For Free',
        name: 'Click_Get Started For Free_Banner1',
        short_name: 'cli_getstaforfre_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-click-contact-learning-advisor-banner1': {  //done
        label: 'Contact Learning Advisor',
        name: 'Click_Contact Learning Advisor_Banner1',
        short_name: 'cli_conleaadv_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-click-view-schedules-banner1': {
        label: 'View Schedules',
        name: 'Click-View-Schedules-Banner1',
        short_name: 'cli_viesch_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-click-watch-introduction-video-banner1': {//done
        label: 'Watch Introduction Video ',
        name: 'Click_Watch Introduction Video_Banner1',
        short_name: 'cli_watintvid_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-click-reviews-rating-banner-1': {
        label: 'Reviews Rating',
        name: 'Click_Reviews Rating_Banner1',
        short_name: 'cli_revrat_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-submit-get-started-for-free-banner1': {
        label: 'Get Started For Free',
        name: 'Submit_Get Started For Free_Banner1',
        short_name: 'sub_getstaforfre_ban1',
        action: 'Submit',
        page_section : 'Banner1'
    },
    'layout-d-submit-contact-learning-advisor-banner1': { // done
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Banner1',
        short_name: 'sub_conleaadv_ban1',
        action: 'Submit',
        page_section : 'Banner1'
    },
    'layout-d-click-refer-and-earn-banner2': { //done
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_Banner2',
        short_name: 'cli_refandear_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'layout-d-click-get-started-for-free-banner2': {
        label: 'Get Started For Free',
        name: 'Click_Get Started For Free_Banner2',
        short_name: 'cli_getstaforfre_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'layout-d-click-contact-learning-advisor-banner2': {  // done
        label: 'Contact Learning Advisor',
        name: 'Click_Contact Learning Advisor_Banner2',
        short_name: 'cli_conleaadv_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'layout-d-click-view-schedules-banner2': {
        label: 'View Schedules',
        name: 'Click_View Schedules_Banner2',
        short_name: 'cli_viesch_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'layout-d-click-watch-introduction-video-banner2': { //done
        label: 'Watch Introduction Video ',
        name: 'Click_Watch Introduction Video_Banner2',
        short_name: 'cli_watintvid_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'layout-d-click-reviews-rating-banner-2': { 
        label: 'Reviews Rating',
        name: 'Click_Reviews Rating_Banner2',
        short_name: 'cli_revrat_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },  
    'layout-d-submit-get-started-for-free-banner2': {
        label: 'Get Started For Free',
        name: 'Submit_Get Started For Free_Banner2',
        short_name: 'sub_getstaforfre_ban2',
        action: 'Submit',
        page_section : 'Banner2'
    },
    'layout-d-submit-contact-learning-advisor-banner2': { //done
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Banner2',
        short_name: 'sub_conleaadv_ban2',
        action: 'Submit',
        page_section : 'Banner2'
    },
    // breakpoint
    'layout-d-click-apply-now-banner3': {
        label: 'Apply Now',
        name: 'Click_Apply Now_Banner3',
        short_name: 'cli_appnow_ban3',
        action: 'Click',
        page_section : 'Banner3'
    },
    'layout-d-click-watch-introduction-video-banner3': { //done
        label: 'Watch Introduction Video',
        name: 'Click_Watch Introduction Video_Banner3',
        short_name: 'cli_watintvid_ban3',
        action: 'Click',
        page_section : 'Banner3'
    },
    'layout-d-submit-weekly-newsletter-bottom-nav': {
        label: 'Watch Introduction Video',
        name: 'Submit_Weekly Newsletter_Bottom Nav',
        short_name: 'sub_weenew_botnav',
        action: 'Submit',
        page_section : 'Bottom Nav'
    },
    // 'layout-d-click-skill-up-your-tech-teams-career-launch': { old
    //     label: 'Skill Up your Tech teams',
    //     name: 'Click_Skill Up your Tech teams_Career Launch',
    //     short_name: 'cli_skiupyoutectea_carlau',
    //     action: 'Click',
    //     page_section : 'Career Launch'
    // },
    'layout-d-click-skill-up-your-tech-teams-career-launch': { //new
        label: 'Request Quote_Corporate Training',
        name: 'Click_Request Quote_Corporate Training_Enterprise',
        short_name: 'cli_reqquo_cortra_ent',
        action: 'Click',
        page_section : 'Enterprise'
    },
    'layout-d-click-skill-up-your-tech-teams-other-info-career-launch': {
        label: 'Skill Up your Tech teams_Other info',
        name: 'Click_Skill Up your Tech teams_Other info_Career Launch',
        short_name: 'cli_skiupyoutectea_othinf_carlau',
        action: 'Click',
        page_section : 'Career Launch'
    },
    'layout-d-submit-get-custom-quote-career-launch': {
        label: 'Get Custom Quote',
        name: 'Submit_Get Custom Quote_Career Launch',
        short_name: 'sub_getcusquo_carlau',
        action: 'Submit',
        page_section : 'Career Launch'
    },
    'layout-d-click-CTA-1': {
        label: 'CTA1',
        name: 'Click_CTA1',
        short_name: 'cli_cta1',
        action: 'Click',
        page_section : 'CTA1'
    },
    'layout-d-submit-drop-a-query-CTA-1': {
        label: 'Drop A Query_CTA1',
        name: 'Submit_Drop A Query_CTA1',
        short_name: 'sub_droaquecta1_cta1',
        action: 'Submit',
        page_section : 'CTA1'
    },
    'layout-d-submit-contact-courseAdvisor-CTA-1': {
        label: 'Contact CourseAdvisor_CTA1',
        name: 'Submit_Contact CourseAdvisor_CTA1',
        short_name: 'sub_concouadvcta1_cta1',
        action: 'Submit',
        page_section : 'CTA1'
    },
    'layout-d-submit-request-a-call-CTA-1': {
        label: 'Request A Call_CTA1',
        name: 'Submit_Request A Call_CTA1',
        short_name: 'sub_reqacallcta1_cta1',
        action: 'Submit',
        page_section : 'CTA1'
    },
    'layout-d-submit-download-syllabus-CTA-1': {
        label: 'Download Syllabus_CTA1',
        name: 'Submit_Download Syllabus_CTA1',
        short_name: 'sub_dowsylcta1_cta1',
        action: 'Submit',
        page_section : 'CTA1'
    },
    'layout-d-submit-request-a-quote-CTA-1': {
        label: 'Request A Quote_CTA1',
        name: 'Submit_Request A Quote_CTA1',
        short_name: 'sub_reqaquocta1_cta1',
        action: 'Submit',
        page_section : 'CTA1'
    },
    'layout-d-click-CTA-2': {
        label: 'CTA2',
        name: 'Click_CTA2',
        short_name: 'cli_cta2',
        action: 'Click',
        page_section : 'CTA2'
    },
    'layout-d-click-CTA-3': {
        label: 'CTA3',
        name: 'Click_CTA3',
        short_name: 'cli_cta3',
        action: 'Click',
        page_section : 'CTA3'
    },	
    'layout-d-click-download-curriculum-curriculum': { // done
        label: 'Download Curriculum',
        name: 'Click_Download Curriculum_Curriculum',
        short_name: 'cli_dowcur_cur',
        action: 'Click',
        page_section : 'Curriculum'
    },	
    'layout-d-submit-download-curriculum-curriculum': { // done
        label: 'Download Curriculum',
        name: 'Submit_Download Curriculum_Curriculum',
        short_name: 'sub_dowcur_cur',
        action: 'Submit',
        page_section : 'Curriculum'
    },	
    'layout-d-click-curriculum-expand-curriculum': { //done
        label: 'Curriculum_Expand',
        name: 'Click_Curriculum_Expand_Curriculum',
        short_name: 'cli_cur_exp_cur',
        action: 'Click',
        page_section : 'Curriculum'
    },	
    'layout-d-click-play-video-curriculum': { // done
        label: 'Play Video',
        name: 'Click_Play Video_Curriculum',
        short_name: 'cli_plavid_cur',
        action: 'Click',
        page_section : 'Curriculum'
    },	
    'layout-d-submit-unlock-preview-curriculum': { //done
        label: 'Unlock Preview',
        name: 'Submit_Unlock Preview_Curriculum',
        short_name: 'sub_unlpre_cur',
        action: 'Submit',
        page_section : 'Curriculum'
    },
    'layout-d-submit-request-quote-corporate-training-enterprise': { //done
        label: 'Request Quote_Corporate Training',
        name: 'Submit_Request Quote_Corporate Training_Enterprise',
        short_name: 'sub_reqquo_cortra_ent',
        action: 'Submit',
        page_section : 'Enterprise'
    },
    'layout-d-click-know-more-corporate-training-enterprise': {
        label: 'Know more_Corporate Training',
        name: 'Click_Know more_Corporate Training_Enterprise',
        short_name: 'cli_knomor_cortra_ent',
        action: 'Click',
        page_section : 'Enterprise'
    },
    'layout-d-click-FAQs-expand-FAQs': { 
        label: 'FAQs_Expand',
        name: 'Click_FAQs_Expand_FAQs',
        short_name: 'cli_faq_exp_faq',
        action: 'Click',
        page_section : 'FAQs'
    },
    'layout-d-click-SEO-FAQs-Expand-FAQs': {// done
        label: 'SEO FAQs_Expand',
        name: 'Click_SEO FAQs_Expand_FAQs',
        short_name: 'cli_seofaq_exp_faq',
        action: 'Click',
        page_section : 'SEO FAQs'
    },
    'layout-d-submit-drop-a-query-footer-sticky': { //done
        label: 'Drop A Query',
        name: 'Submit_Drop A Query_Footer Sticky',
        short_name: 'sub_droaquecta1_fotsti',
        action: 'Submit',
        page_section : 'Footer Sticky'
    },
    'layout-d-submit-request-a-call-footer-sticky': { //done
        label: 'Request A Call',
        name: 'Submit_Request A Call_Footer Sticky',
        short_name: 'sub_reqacall_fotsti',
        action: 'Submit',
        page_section : 'Footer Sticky'
    },
    'layout-d-click_-drop-a-query-footer-sticky': { //done
        label: 'Drop A Query',
        name: 'Click_Drop A Query_Footer Sticky',
        short_name: 'cli_droaquecta1_fotsti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-click-request-a-call-footer-sticky': { // done
        label: 'Request A Call',
        name: 'Click_Request A Call_Footer Sticky',
        short_name: 'cli_reqacall_fotsti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-click-request-a-batch-footer-sticky': { //done
        label: 'Request A Batch',
        name: 'Click_Request A Batch_Footer Sticky',
        short_name: 'cli_reqabat_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-submit_request-a-batch-footer-sticky': { //done
        label: 'Request A Batch',
        name: 'Submit_Request A Batch_Footer Sticky',
        short_name: 'sub_reqabat_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-click-become-an-instructor-instructors': {
        label: 'Become an Instructor',
        name: 'Click_Become an Instructor_Instructors',
        short_name: 'cli_becanins_ins',
        action: 'Click',
        page_section : 'Instructors'
    },
    'layout-d-click-contact-learning-advisor-overview': { // done
        label: 'Contact Learning Advisor',
        name: 'Click_Contact Learning Advisor_Overview',
        short_name: 'cli_conleaadv_ove',
        action: 'Click',
        page_section : 'Overview'
    },
    'layout-d-submit-contact-learning-advisor-overview': {
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Overview',
        short_name: 'sub_conleaadv_ove',
        action: 'Submit',
        page_section : 'Overview'
    },
    'layout-d-click-overview-1-layout-d-page-top-navigation': { //done
        label: 'Overview',
        name: 'Click_Overview_Page Top Navigation',
        short_name: 'cli_ove_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-knowledgehut-advantage-1-layout-d-page-top-navigation':{ //done
        label: 'Why KH',
        name: 'Click_Knowledgehut_Advantage_Page Top Navigation',
        short_name: 'cli_whykh_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-why-kh-page-top-navigation': { //done
        label: 'Why KH',
        name: 'Click_Why KH_Page Top Navigation',
        short_name: 'cli_whykh_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-prerequisites-1-layout-d-page-top-navigation':{ //done
        label: 'Prerequisites',
        name: 'Click_Prerequisites_Page Top Navigation',
        short_name: 'cli_pre_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },    
    'layout-d-click-project-1-layout-d-page-top-navigation': { // done
        label: 'Projects',
        name: 'Click_Projects_Page Top Navigation',
        short_name: 'cli_pro_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-career-launch-page-top-navigation': {
        label: 'Career Launch',
        name: 'Click_Career Launch_Page Top Navigation',
        short_name: 'cli_carlau_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-curriculum-1-layout-d-page-top-navigation': {  //done
        label: 'Curriculum',
        name: 'Click_Curriculum_Page Top Navigation',
        short_name: 'cli_cur_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-faq-1-layout-d-page-top-navigation': { //done
        label: 'FAQs',
        name: 'Click_FAQs_Page Top Navigation',
        short_name: 'cli_faq_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-for-enterprise-page-top-navigation': {
        label: 'For Enterprise',
        name: 'Click_For Enterprise_Page Top Navigation',
        short_name: 'cli_forent_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-for-enterprise-page-top-navigation-other-information-button': { //done
        label: 'For Enterprise',        
        name: 'Click_For Enterprise_Page Top Navigation Other information + button',    
        short_name: 'cli_forent_pagtopnavothinf+but',    
        action: 'Click',        
        page_section : 'Page Top Navigation Other information'        
    },
    'layout-d-click-enrol-now-page-top-navigation': { //done
        label: 'Enrol Now',
        name: 'Click_Enrol Now_Page Top Navigation',
        short_name: 'cli_enrnow_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-knowledgehutexperience-1-layout-d-page-top-navigation': { //done
        label: 'Knowledgehut Experience',
        name: 'Click_Knowledgehut Experience_Page Top Navigation',
        short_name: 'cli_knoexp_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-take2-1-layout-d-page-top-navigation': { //done
        label: 'Take2',
        name: 'Click_Take2_Page Top Navigation',
        short_name: 'cli_tak2_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-bootcamp-path-1-layout-d-page-top-navigation': { //done
        label: 'Bootcamp Path',
        name: 'Click_Bootcamp Path_Page Top Navigation',
        short_name: 'cli_boopat_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-bootcamp-career-1-layout-d-page-top-navigation': { //done
        label: 'Bootcamp Career',
        name: 'Click_Bootcamp career_Page Top Navigation',
        short_name: 'cli_boocar_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-path-to-certification-1-layout-d-page-top-navigation': { //done
        label: 'Path to Certification',
        name: 'Click_Path to Certification_Page Top Navigation',
        short_name: 'cli_pattocer_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-who-should-attend-1-layout-d-page-top-navigation': {  //done
        label: 'Who should Attend',
        name: 'Click_Who should Attend_Page Top Navigation',
        short_name: 'cli_whoshoatt_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    }, 
    'layout-d-click-bootcamp-courses-1-layout-d-page-top-navigation': { //done
        label: 'Bootcamp Courses',
        name: 'Click_Bootcamp Courses_Page Top Navigation',
        short_name: 'cli_boocou_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-cta-1-layout-d-page-top-navigation': {  //done
        label: 'CTA1',
        name: 'Click_CTA1_Page Top Navigation',
        short_name: 'cli_cta1_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-cta-2-layout-d-page-top-navigation': {
        label: 'CTA2',
        name: 'Click_CTA2_Page Top Navigation',
        short_name: 'cli_cta2_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-cta-3-layout-d-page-top-navigation': {
        label: 'CTA3',
        name: 'Click_CTA3_Page Top Navigation',
        short_name: 'cli_cta3_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-concept-1-layout-d-page-top-navigation': { //done
        label: 'Concept',
        name: 'Click_Concept_Page Top Navigation',
        short_name: 'cli_con_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-suggested-courses-1-layout-d-page-top-navigation': { //done
        label: 'Suggested Courses',
        name: 'Click_Suggested Courses_Page Top Navigation',
        short_name: 'cli_sugcou_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-hiring-partners-1-layout-d-page-top-navigation': { //done
        label: 'Hiring Partner',
        name: 'Click_Hiring Partner_Page Top Navigation',
        short_name: 'cli_hirpar_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-seo-unique-content-1-layout-d-page-top-navigation': { //done
        label: 'SEO Faqs',
        name: 'Click_SEO Faqs_Page Top Navigation',
        short_name: 'cli_seofaq_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-trainer-1-layout-d-page-top-navigation': { //done
        label: 'Trainers',
        name: 'Click_Trainers_Page Top Navigation',
        short_name: 'cli_tri_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-review-1-layout-d-page-top-navigation': { //done
        label: 'Reviews',
        name: 'Click_Reviews_Page Top Navigation',
        short_name: 'cli_rev_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-skill-set-1-layout-d-page-top-navigation': { //done
        label: 'Skill Set',
        name: 'Click_Skill Set_Page Top Navigation',
        short_name: 'cli_skiset_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-refer-and-earn-1-layout-d-page-top-navigation': { //done
        label: 'Refer and Earn',
        name: 'Click_Refer and Earn_Page Top Navigation',
        short_name: 'cli_refear_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-submit-for-enterprise-get-custom-quote-page-top-navigation': { //done
        label: 'For Enterprise_Get Custom Quote',
        name: 'Submit_For Enterprise_Get Custom Quote _Page Top Navigation',
        short_name: 'sub_forent_getcusquo_pagtopnav',
        action: 'Submit',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-corporate-1-layout-d-page-top-navigation':{ //done
        label: 'Corporate',
        name: 'Click_Corporate-1_Page Top Navigation',
        short_name: 'cli_corp_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-when-1-layout-d-page-top-navigation': { //done
        label: 'Tuition',
        name: 'Click_Tuition_Page Top Navigation',
        short_name: 'cli_tui_pagtopnav',
        action: 'Click',
        page_section : 'Page Top Navigation'
    },
    'layout-d-click-refer-now-refer-and-earn': {
        label: 'Refer Now',
        name: 'Click_Refer Now_Refer and Earn',
        short_name: 'cli_refnow_refandear',
        action: 'Click',
        page_section : 'Refer and Earn'
    },
    'layout-d-submit-got-a-questions-sticky-nav': {
        label: 'Got A Questions',
        name: 'Submit_Got A Questions_Sticky Nav',
        short_name: 'sub_gotaque_stinav',
        action: 'Submit',
        page_section : 'Sticky Nav'
    },
    'layout-d-click-live-chat-sticky-nav': {
        label: 'Live Chat',
        name: 'Click_Live Chat_Sticky Nav',
        short_name: 'cli_livcha_stinav',
        action: 'Click',
        page_section : 'Sticky Nav'
    },
    'layout-d-submit-save-zopim-live-chat-sticky-nav': {
        label: 'Save Zopim Live Chat',
        name: 'Submit_Save Zopim Live Chat_Sticky Nav',
        short_name: 'sub_savzoplivcha_stinav',
        action: 'Submit',
        page_section : 'Sticky Nav'
    },
    'layout-d-click-contact-course-advisor-sticky-nav-RHS': {
        label: 'Contact Course Advisor',
        name: 'Click_Contact Course Advisor_Sticky Nav RHS',
        short_name: 'sub_concouadv_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'layout-d-click-schedule-a-call-sticky-nav-RHS': {
        label: 'Schedule A Call',
        name: 'Click_Schedule A Call_Sticky Nav RHS',
        short_name: 'sub_schacall_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'layout-d-click-request-quote-corporate-training-sticky-nav-RHS': {
        label: 'Request Quote_Corporate Training',
        name: 'Click_Request Quote_Corporate Training_Sticky Nav RHS',
        short_name: 'cli_reqquo_cortra_stinavrhs',
        action: 'Click',
        page_section : 'Sticky Nav RHS'
    },
    'layout-d-submit-request-quote-corporate-training-sticky-nav-RHS': {
        label: 'Request Quote_Corporate Training',
        name: 'Submit_Request Quote_Corporate Training_Sticky Nav RHS',
        short_name: 'sub_reqquo_cortra_stinavrhs',
        action: 'Submit',
        page_section : 'Sticky Nav RHS'
    },
    'layout-d-click-join-whatsapp-group-success-opup': {
        label: 'Join Now',
        name: 'Click_Join Whatsapp Group_Success Popup',
        short_name: 'cli-joiwhagro_sucpop',
        action: 'Click',
        page_section : 'Success Popup'
    },
    'layout-d-click-view-all-schedules-tuition-1': {
        label: 'View All Schedules',
        name: 'Click_View All Schedules_Tuition 1',
        short_name: 'cli_vieallsch_tui1',
        action: 'Click',
        page_section : 'Tuition1'
    },
    'layout-d-click-schedule-enrol-now-tuition1': {
        label: 'Schedule Enrol Now ',
        name: 'Click_Schedule Enrol Now_Tuition1',
        short_name: 'cli_schenrnow_tui1',
        action: 'Click',
        page_section : 'Tuition1'
    },
    'layout-d-click-request-a-quote-tuition1': { //done
        label: 'Request A Batch',
        name: 'Click_Request A Quote_Tuition1',
        short_name: 'cli_reqabat_tui1',
        action: 'Click',
        page_section : 'Tuition1'
    },
    'layout-d-submit-request-a-quote-tuition1': {
        label: 'Request A Batch',
        name: 'Submit_Request A Quote_Tuition1',
        short_name: 'sub_reqabat_tui1',
        action: 'Submit',
        page_section : 'Tuition1'
    },
    //------------------------------------------//
    'layout-d-click-contact-us-tuition2': {
    }, //breakpoint need to add layout d prefix
    'click-contact-us-tuition2': {
        label: 'Contact us',
        name: 'Click_Contact us_Tuition2',
        short_name: 'cli_conus_tui2',
        action: 'Click',
        page_section : 'Tuition2'
    },
    'layout-d-submit-contact-learning-advisor-tuition2': {
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Tuition2',
        short_name: 'sub_conleaadvi_tui2',
        action: 'Submit',
        page_section : 'Tuition2'
    },
    'layout-d-click-apply-for-scholarship-tuition2': {
        label: 'Apply For Scholarship',
        name: 'Click_Apply For Scholarship_Tuition2',
        short_name: 'cli_appforsch_tui2',
        action: 'Click',
        page_section : 'Tuition2'
    },
    'layout-d-click-enrol-now-lvc-tuition3': {
        label: 'Enrol Now_LVC',
        name: 'Click_Enrol Now LVC_Tuition3',
        short_name: 'cli_enrnowlvc_tui3',
        action: 'Click',
        page_section : 'Tuition3'
    },
    'layout-d-click-enrol-now-classroom-tuition3': {
        label: 'Enrol Now_Classroom',
        name: 'Click_Enrol Now Classroom_Tuition3',
        short_name: 'cli_enrnowcla_tui3',
        action: 'Click',
        page_section : 'Tuition3'
    },

    'layout-d-click-enrol-now-osl-tuition3': {
        label: 'Enrol Now_OSL',
        name: 'Click_Enrol Now OSL_Tuition3',
        short_name: 'cli_enrnowosl_tui3',
        action: 'Click',
        page_section : 'Tuition3'
    },
    'layout-d-click-view-all-schedules-tuition4': {
        label: 'View All Schedules',
        name: 'Click_View All Schedules_Tuition4',
        short_name: 'cli_vieallsch_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-click-enrol-now-upfront-payments-tuition4': {
        label: 'Enrol Now_Upfront Payment',
        name: 'Click_Enrol Now_Upfront Payments_Tuition4',
        short_name: 'cli_enrnowupfpay_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-click-apply-now-certification-tuition4': {
        label: 'Apply Now_Certification ',
        name: 'Click_Apply Now_Certification _Tuition4',
        short_name: 'cli_appnowcert_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-click-apply-now-pay-when-you-earn-tuition4': {
        label: 'Apply Now_Pay when you earn',
        name: 'Click_Apply Now_Pay when you earn_Tuition4',
        short_name: 'cli_appnowpaywheyouear_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-click-apply-now-scholarship-tuition4': {
        label: 'Apply Now_Scholarship',
        name: 'Click_Apply Now_Scholarship_Tuition4',
        short_name: 'cli_appnowsch_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-submit-apply-now-certification-tuition4': {
        label: 'Apply Now_Certification ',
        name: 'Submit_Apply Now_Certification _Tuition4',
        short_name: 'cli_appnowcert_tui4',
        action: 'Submit',
        page_section : 'Tuition4'
    },
    'layout-d-submit-apply-now-pay-when-you-earn-tuition4': {
        label: 'Apply Now_Pay when you earn',
        name: 'Submit_Apply Now_Pay when you earn_Tuition4',
        short_name: 'cli_appnowpaywheyouear_tui4',
        action: 'Submit',
        page_section : 'Tuition4'
    },
    'layout-d-submit-apply-now-scholarship-tuition4': {
        label: 'Apply Now_Scholarship',
        name: 'Submit_Apply Now_Scholarship_Tuition4',
        short_name: 'cli_appnowsch_tui4',
        action: 'Submit',
        page_section : 'Tuition4'
    },
    'layout-d-click-enrol-now-self-paced-tuition4': {
        label: 'Enrol Now_Self Paced',
        name: 'Click_Enrol Now_Self Paced_Tuition4',
        short_name: 'cli_enrnowselpac_tui4',
        action: 'Click',
        page_section : 'Tuition4'
    },
    'layout-d-click-apply-now-upfront-payment-tuition5': {
        label: 'Apply Now_Upfront Payment',
        name: 'Click_Apply Now_Upfront Payment_Tuition5',
        short_name: 'cli_appnow_upfpay_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-click-apply-now-upfront-payment-more-details-tuition5': {
        label: 'Apply Now_Upfront Payment More Details',
        name: 'Click_Apply Now_Upfront Payment More Details_Tuition5',
        short_name: 'cli_appnow_upfpaymordet_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-submit-get-started-upfront-payment-Tuition5': {
        label: 'Get Started_Upfront Payment More Details',
        name: 'Submit_Get Started_Upfront Payment_Tuition5',
        short_name: 'sub_getsta_upfpay_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-click-apply-now-pay-after-job-placement-tuition5': {
        label: 'Apply Now_Pay After Job Placement',
        name: 'Click_Apply Now_Pay After Job Placement_Tuition5',
        short_name: 'cli_appnow_payaftjobpla_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-click-apply-now-pay-after-job-placement-more-details-tuition5': {
        label: 'Apply Now_Pay After Job Placement More Details',
        name: 'Click_Apply Now_Pay After Job Placement More Details_Tuition5',
        short_name: 'cli_appnow_payaftjobplamordet_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-submit-get-started-pay-after-job-placement-tuition5': {
        label: 'Get Started_Pay After Job Placement',
        name: 'Submit_Get Started_Pay After Job Placement_Tuition5',
        short_name: 'sub_getsta_payaftjobpla_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-click-get-apply-now-monthly-payment-tuition5': {
        label: 'Apply Now_Monthly Payment ',
        name: 'Click_Get Apply Now_Monthly Payment_Tuition5',
        short_name: 'cli_appnow_monpay_tui_sch5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-submit-get-started-monthly-payment-tuition5': {
        label: 'Get Started_Monthly Payment ',
        name: 'Submit_Get Started_Monthly Payment_Tuition5',
        short_name: 'sub_getsta_monpay_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-submit-monthly-payment-course-application-tuition5': {
        label: 'Submit_Monthly Payment_Course_Application ',
        name: 'Submit_Monthly Payment_Course_Application _Tuition5',
        short_name: 'su_monpay_cou_app_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-click-get-apply-now-scholarship-tuition5': {
        label: 'Apply Now_Scholarship',
        name: 'Click_Get Apply Now_Scholarship_Tuition5',
        short_name: 'cli_appnow_sch_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-submit-get-started-scholarship-tuition5': {
        label: 'Get Started_Scholarship',
        name: 'Submit_Get Started_Scholarship_Tuition5',
        short_name: 'sub_getsta_sch_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-submit-scholarship-course-application-Tuition5': {
        label: 'Submit_Scholarship_Course_Application',
        name: 'Submit_Scholarship_Course_Application_Tuition5',
        short_name: 'sub_sch_cou_app_tui5',
        action: 'Submit',
        page_section : 'Tuition5'
    },
    'layout-d-click-enrol-now-self-paced-tuition5': {
        label: 'Enrol Now_Self Paced',
        name: 'Click_Enrol Now_Self Paced_Tuition5',
        short_name: 'cli_enrnow_selpac_tui5',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-click-view-all-schedules-tuition5': {
        label: 'View All Schedules',
        name: 'Click_View All Schedules_Tuition5',
        short_name: 'cli_vieallsch_tui4',
        action: 'Click',
        page_section : 'Tuition5'
    },
    'layout-d-click-contact-learning-advisor-why-kh': {
        label: 'Contact Learning Advisor',
        name: 'Click_Contact Learning Advisor_Why KH',
        short_name: 'cli_conleaadv_whykh',
        action: 'Click',
        page_section : 'Why KH'
    },
    'layout-d-submit-contact-learning-advisor-why-kh': {
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Why KH',
        short_name: 'sub_conleaadv_whykh',
        action: 'Submit',
        page_section : 'Why KH'
    },
    'layout-d-click-get-started-for-free-why-kh': {
        label: 'Get Started For Free',
        name: 'Click_Get Started For Free_Why KH',
        short_name: 'cli_getstaforfre_whykh',
        action: 'Click',
        page_section : 'Why KH'
    },
    'layout-d-submit-get-started-for-free-why-kh': {
        label: 'Get Started For Free',
        name: 'Submit_Get Started For Free_Why KH',
        short_name: 'sub_getstaforfre_whykh',
        action: 'Submit',
        page_section : 'Why KH'
    },  

    'layout-d-click-here-request-quote': {
        label: 'Click Here_Request Quote',
        name: 'Click_Request Quote_Banner', 
        short_name: 'click_reqquo_Banner',
        action: 'Click',
        page_section : 'Banner'
    },
    'layout-d-submit-here-request-quote': {
        label: 'Click Here_Request Quote',
        name: 'Submit_Request Quote_Banner', 
        short_name: 'submit_reqquo_Banner',
        action: 'Submit',
        page_section : 'Banner'
    },
    'layout-d-click-get-started-for-free-tuition1': {
        label: 'Get Started For Free',
        name: 'Click_Get Started For Free_Tuition1',
        short_name: 'cli_getstaforfre_tui1',
        action: 'Click',
        page_section : 'Why KH'
    },
    'layout-d-submit-get-started-for-free-tuition1': {
        label: 'Get Started For Free',
        name: 'Submit_Get Started For Free_Tuition1',
        short_name: 'sub_getstaforfre_tui1',
        action: 'Submit',
        page_section : 'Why KH'
    },
    'layout-d-click-request-a-batch-footer-sticky-mbl': { //done
        label: 'Request A Batch',
        name: 'Click_Request A Batch_Footer Sticky',
        short_name: 'cli_reqabat_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-submit-request-a-batch-footer-sticky-mbl': { //need to add
        label: 'Request A Batch',
        name: 'Submit_Request A Batch_Footer Sticky',
        short_name: 'sub_reqabat_foosti',
        action: 'Submit',
        page_section : 'Footer Sticky'
    },
    'layout-d-click-contact-learning-advisor-footer-sticky-mbl': { //need to add
        label: 'Contact Learning Advisor',
        name: 'Click_Contact Learning Advisor_Footer Sticky',
        short_name: 'cli_conleaadv_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },

    'layout-d-submit-contact-learning-advisor-footer-sticky-mbl': { //need to add
        label: 'Contact Learning Advisor',
        name: 'Submit_Contact Learning Advisor_Footer Sticky',
        short_name: 'sub_conleaadv_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-click-enrol-now-footer-sticky-mbl': {
        label: 'Enrol Now',
        name: 'Click_Enrol Now_Footer Sticky',
        short_name: 'cli_enrnow_foosti',
        action: 'Click',
        page_section : 'Footer Sticky'
    },
    'layout-d-submit-request-a-quote-banner1': { //added
        label: 'Request A Quote',
        name: 'Submit_Request A Quote_Banner1',
        short_name: 'sub_reqaquo_ban1',
        action: 'Submit',
        page_section : 'Banner1'
    },
    'layout-d-click_request-a-quote-banner1': { //added
        label: 'Request A Quote',
        name: 'Click_Request A Quote_Banner1',
        short_name: 'cli_reqaquo_ban1',
        action: 'Click',
        page_section : 'Banner1'
    },
    'layout-d-submit-request-a-quote-banner2': { //added
        label: 'Request A Quote',
        name: 'Submit_Request A Quote_Banner2',
        short_name: 'sub_reqaquo_ban2',
        action: 'Submit',
        page_section : 'Banner2'
    },
    'layout-d-click_request-a-quote-banner2': { //added
        label: 'Request A Quote',
        name: 'Click_Request A Quote_Banner2',
        short_name: 'cli_reqaquo_ban2',
        action: 'Click',
        page_section : 'Banner2'
    },
    'mega_menu_click_menu_items': {
        label: '{value} Category',
        name: 'Click_{value} Category_Courses Navigation',
        short_name: 'cli_{value}cat_counav',
        action: 'Click',
        page_section : 'Courses Navigation'
    },
    'mega_menu_click_course_menu_items': {
        label: 'Course',
        name: 'Click_Course_Courses Navigation',
        short_name: 'cli_cou_counav',
        action: 'Click',
        page_section : 'Courses Navigation',
        parameters : {
          course : '{value}'
        }
    },
    'mega_menu_click_view_all_courses': {
        label: 'View all courses',
        name: 'Click _View all courses_Header',
        short_name: 'cli_vieallcou_hea',
        action: 'Click',
        page_section : 'Header'
    },
    'homepage_click_Khut_logo_header': {
        label: 'Khut logo',
        name: 'Click _Khut logo_Header',
        short_name: 'cli_khulog_hea',
        action: 'Click',
        page_section : 'Header'
    },
    "all_page_header_click_search" : { 
        label : 'Search',
        name : 'Click_Search_header',
        short_name : 'cli_sea_hea',
        action : 'Click',
        page_section : 'Header' 
    },
    "homepage_hover_courses_header" : { 
        label : 'Courses',
        name : 'Hover_Courses_Header',
        short_name : 'hov_cou_hea',
        action : 'Hover',
        page_section : 'Header' 
    },
    'hover-header-menu': {
        label: '{value}',
        name: 'Hover_{value} _Header',
        short_name: 'hov_{value}_hea',
        action: 'Hover',
        page_section : 'Header'
    },
};