// import Button from 'components/Category/common/Button';
import Link from 'next/link';
import { useState } from 'react';
import { CartBlack, HeaderSprite } from '../Icon';
// import BlogSubscribeModal from "../BlogSubscribeModal";
// import Button from "../Button/index";
import MegaMenu from '../MegaMenu';
import MenuItem from '../MenuItems';
import { BootcampData, EnterpriseData, HelpData, ResourcesData } from '../MenuItems/Data';
import { MenuItemsProps } from '../MenuItems/Interface';
import SearchBar from '../SearchBar';
import HeaderIcon from './HeaderIcon';
import ShadowAuthButton from '../ShadowAuthButton';
// import HeaderSearchBar from './HeaderSearchBar';

// type Props = {
// 	MenuItemsProps: MenuItemsProps;
// 	MegaMenuProps: any;
// };

const HeaderLargeScreen: React.FC = () => {
  // const [open, setOpen] = useState(false);
  return (
    <div className="container-fluid hidden lg:block">
      <nav className="hidden lg:grid lg:grid-cols-2">
        <div className="flex items-center">
          {/* Header Image */}
          <HeaderIcon />

          {/* MegaMenu */}
          <div className="pr-3 pl-12">
            <MegaMenu />
          </div>

          {/* Search bar */}
          <div className="w-[281px]">
            <SearchBar />
          </div>
        </div>

        <div className="flex justify-end items-center">
          {/* Menu items */}
          <MenuItem toShow={0} title="Bootcamps" data={BootcampData} isShowNewTag={true} />
          <MenuItem toShow={0} title="Enterprise" data={EnterpriseData} />
          {/* Link to Blog */}

          {/* <a href="/blog">
            <div className="inline-block mr-5 py-5 text-gray-5 text-[13px] font-semibold border-b-2 border-white cursor-pointer hover:border-black leading-6">
              Blog
            </div>
          </a> */}

          {/* Menu items */}
          <MenuItem toShow={0} title="Resources" data={ResourcesData} />
          <MenuItem toShow={0} title="Help" data={HelpData} />

          {/* Cart icon */}
          <div className="flex justify-evenly mr-6">
            <div className="group">
              <div className="py-5 text-gray-5 h-[68px] text-[13px] font-semibold border-b-2 border-white cursor-pointer group-hover:border-black leading-[26px] duration-[0.3s]">
                <a href="/cart" className="block h-4">
                  <CartBlack className="mt-[5px]" />
                </a>
              </div>
            </div>
          </div>

          {/* SignIn button */}
          <div className="flex justify-evenly">
            {/* <a rel="nofollow" href="https://prism.knowledgehut.com/">
              <div className="inline-flex items-center justify-center h-9 text-[13px] leading-5 font-semibold text-custom-gray-200 bg-[#f0f0f0] px-[9px] py-2 rounded border border-transparent">
                Sign In
              </div>
            </a> */}
            <ShadowAuthButton />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderLargeScreen;
