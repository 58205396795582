import React, { useState } from 'react';
import Link from 'next/link';
import menuDetails from '../MegaMenu/Data';
import { MegaMenuIcon } from '../Icon';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const MegaMenu: React.FC = () => {
  const [currentItem, setCurrentItem] = useState(menuDetails[0]);
  const [isShowMegaMenu, setIsShowMegaMenu] = useState(false);

  const submenu = (id: string) => {
    menuDetails.forEach((item) => {
      if (item.id === id) {
        setCurrentItem(item);
        return;
      }
    });
  };

  return (
    <div className="group">
      <div
        className="flex relative items-center bg-[#f0f0f0]  h-9 px-3 cursor-pointer rounded justify-center border border-transparent"
        onMouseEnter={() => {setIsShowMegaMenu(true); trackGa4Events('homepage_hover_courses_header'); } }
      >
        <span className="text-xs font-semibold pr-3 text-custom-gray-200"> Courses</span>
        <MegaMenuIcon />
      </div>

      {isShowMegaMenu && (
        <div className="absolute z-50 max-h-[470px] 3xl:left-[220px] 3xl:transform-none left-1/2 -translate-x-1/2 container hidden group-hover:block">
          <div className="h-4 bg-transparent"></div>
          <div className="flex h-full border bg-white border-gray-300">
            {/* category section */}
            <section className="w-72 border-r border-gray-9">
              <div className="pl-5 py-3 font-semibold text-base">Category</div>

              <div className="overflow-y-auto max-h-[420px] customized-scrollbar">
                {menuDetails?.map((item, index) => {
                  return (
                    <ul className="flex" key={index}>
                      <a
                        id="mega-menu-right-arrow"
                        href={item.link}
                        className="flex group-scope  hover:bg-gray-300 px-5 h-9 w-full justify-between items-center"
                        onMouseOver={() => {
                          submenu(item.id);
                        }}
                        onClick={() => {
                           trackGa4Events(`mega_menu_click_menu_items{${item.name}}`);
                        }}
                      >
                        <li className="text-sm">{item.name}</li>

                        <span>
                          <ChevronRightIcon className="w-5 text-gray-500 transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100" />
                        </span>

                        {/* <i className="blog-icons icon-right-arrow-small inline-block transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100"></i> */}
                      </a>
                    </ul>
                  );
                })}
              </div>
            </section>
            {/* course section */}
            <section className="w-2/5 border-r pl-8 border-gray-9">
              <div className="pl-1 py-3 font-semibold text-base">Courses</div>
              <div className="overflow-y-auto max-h-[420px] customized-scrollbar">
                {currentItem.courses.map((item: { courseUrl: string; courseName: string }, id: any) => {
                  return (
                    <div className="flex flex-col" key={id}>
                      <a className="p-1 line-clamp-1" href={item.courseUrl} onClick={() => {
                           trackGa4Events(`mega_menu_click_course_menu_items{${item.courseName}}`, {parameters : {category : currentItem.name }});
                        }}
                        >
                        <span
                          className=" hover:text-[#26d400] text-sm"
                          dangerouslySetInnerHTML={{
                            __html: item.courseName,
                          }}
                        ></span>
                      </a>
                    </div>
                  );
                })}
                {currentItem.courses.length >= 10 && (
                  <a
                    id="mega-menu-right-arrow"
                    href={currentItem.link}
                    className="flex items-center group-scope cursor-pointer w-40 text-sm hover:text-[#26d400] font-semibold px-2 py-4"
                    onClick={() => {
                      trackGa4Events('mega_menu_click_view_all_courses');
                   }}
                  >
                    <p>View All Courses</p>

                    <span>
                      <ChevronRightIcon className="w-7 text-[#26d400] transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100" />
                    </span>

                    {/* <i className="inline-block icon-right-arrow-green blog-icons transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100"></i> */}
                  </a>
                )}
              </div>
            </section>

            {/* Bootcamps section */}
            <section className="w-2/5">
              {currentItem.accredition && (
                <>
                  <div className="font-semibold text-base pl-6 py-3">Accredition bodies</div>
                  <div className="overflow-y-auto max-h-[420px] customized-scrollbar">
                    <div className="flex flex-wrap gap-6 p-6 justify-start">
                      {currentItem?.accredition?.map((item: any, id: number) => {
                        return (
                          <div key={id} className="flex gap-3">
                            <i className={`${item.accreditionIcon} acdtn-icons  inline-block`}></i>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {currentItem.bootcamps && (
                <>
                  <div className="font-semibold text-base pl-9 pt-3 pb-2">Career Track</div>
                  <div className="overflow-y-auto max-h-[420px] customized-scrollbar">
                    {currentItem?.bootcamps?.map((item: any, id: number) => {
                      return (
                        <div
                          className="flex flex-col gap-2 text-sm pb-7 ml-9 border-b w-11/12 m-auto border-gray-9"
                          key={id}
                        >
                          <a href={item.bootcampsUrl} className="pt-3 font-semibold">
                            {item.bootcampsName}
                          </a>
                          <p>{item.bootcampsDescription}</p>
                          <a
                            id="mega-menu-right-arrow"
                            className="flex items-center group-scope"
                            href={item.bootcampsUrl}
                          >
                            KNOW MORE
                            <span className="flex items-center">
                              <ChevronRightIcon className="w-7 text-[#26d400] transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100" />
                              {/* <i className="inline-block icon-right-arrow-green blog-icons transition transform group-scope-hover:translate-x-1 ease-linear group-scope-hover:scale-100"></i> */}
                            </span>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default MegaMenu;
