// import { moengageEventTrack } from './moengage';
import * as gtag from '../../lib/events/gtag';
import { getUserLocationData } from '../../lib/common/userLocation';

interface trackerProps {
  trackerType: 'ga' | 'moengage' | 'all';
  eventLabel?: string;
  eventName?: string;
  eventAction: string;
  eventActionType?: string;
  data?: any;
}

// eventActionType --- is for master class submit

export const tracker = async (props: trackerProps) => {
  // set
  let { eventAction, eventName, eventLabel, data, trackerType, eventActionType } = props;

  // Get URL and check the domain and set prefix
  let newUrl = new URL(window.location.href);

  let domain = newUrl.hostname;
  if (domain.includes('www.')) {
    domain = domain.replace('www.', '');
    if (domain.includes('.com')) {
      domain = domain.replace('.com', '');
    }
  } else if (domain.includes('.com')) {
    domain = domain.replace('.com', '');
  }

  let eventPrefix: string = '';
  if (domain !== 'knowledgehut') {
    eventName = `${domain}_${eventName}`;
    eventLabel = `${domain}${' '}${eventLabel}`;
    eventPrefix = `${domain}${' '}`;
  } else {
    domain = '';
  }

  // location
  let location = await getUserLocationData();

  // location data
  let locationData = {
    Timezone: location.city.timezone_name,
    Country: location.country.name,
    City: location.city.name,
    eventPrefix: eventPrefix,
  };

  // data
  data = { ...locationData, ...data };

  let moengageData = { eventAction: `${domain}${' '}${eventAction}`, user: {}, data: {} };

  if (eventAction == 'Submit' || eventActionType == 'Submit') {
    moengageData.user = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      course: data?.course_name,
      isd: data?.isd,
      mobile: data?.phone,
      leadIdentifier: data?.leadIdentifier,
    };

    if (eventActionType == 'Submit') {
      moengageData.data = {
        timezone: data?.timezone_name || locationData.Timezone,
        country: data?.Country || locationData.Country,
        city: data?.City || locationData.City,
        course_name: data?.program || '',
        label: eventLabel,
        name: eventName,
        action: `${domain}${' '}${eventAction}`,
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        mobile: data?.phone,
        enableWhatsApp: data?.enableWhatsApp,
        yearsOfExperience: data?.yearsOfExperience,
        query: data?.query,
        utmFormId: data?.utmFormId,
        utmMedium: data?.utmMedium,
        utmSource: data?.utmSource,
        utmCampaign: data?.utmCampaign,
        utmTerm: data?.utmTerm,
        utmContent: data?.utmContent,
        URL: data?.url,
        leadIdentifier: data?.leadIdentifier,
        website: domain == 'upgrad' ? 'UPGRAD_BOOTCAMPS' : domain,
      };
    } else {
      moengageData.data = {
        Timezone: data?.timezone_name || locationData.Timezone,
        Country: data?.Country || locationData.Country,
        City: data?.City || locationData.City,
        Course: data?.course_name || '',
        Label: eventLabel,
        Name: eventName,
        Message: data?.query,
        Action: eventAction,
        website: domain == 'upgrad' ? 'UPGRAD_BOOTCAMPS' : domain,
      };
    }
  } else if (eventAction === 'Course Viewed') {
    moengageData.data = {
      Action: `${domain}${' '}${eventAction}`,
      ...data,
      ...locationData,
    };
  } else {
    moengageData.data = {
      Action: `${data.eventPrefix}${eventAction}`,
      Label: eventLabel,
      Name: eventName,
      ...data,
      website: domain == 'upgrad' ? 'UPGRAD_BOOTCAMPS' : domain,
    };
  }

  switch (trackerType) {
    case 'ga':
      // gtag event
      gtag.event({ ...data.eventData });
      break;
    case 'moengage':
      //moengage
      // moengageEventTrack(moengageData);
      break;
    default:
      // gtag event
      gtag.event({ action: eventAction, name: eventName, label: eventLabel });
      //moengage
      // moengageEventTrack(moengageData);
      break;
  }
};
