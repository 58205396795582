import { useEffect } from 'react';
import { EventData } from './constants';
import { tracker } from './tracker';

const ClickEvent = (props: { windowObj: any; courseName: string; categoryName: string }) => {
  let { courseName, categoryName, windowObj } = props;

  // Uncomment this to debug moengage clicks
  // windowObj?.document?.querySelectorAll('*').forEach(e => e.addEventListener('click', (e) => e.preventDefault()));
  // console.log("length - ", windowObj?.document?.querySelectorAll('[data-tracker-id]').length);

  const addClick = (event: Event) => {
    // event.preventDefault();
    let trackerID = (event.target as HTMLElement).getAttribute('data-tracker-id');

    // Check for actual elemenet where the data-tracker-id set and having class name
    if(typeof trackerID == 'undefined' || trackerID == null)
    {
      const target = (event.target as HTMLElement)
      const targetEle = target.closest('.target-link-ele');
      
      if(typeof targetEle !== 'undefined' && targetEle !== null)
        trackerID = target.closest('.target-link-ele').getAttribute('data-tracker-id');
    }
    
    let data = EventData[trackerID];
    
    // For Banner Social icons
    let SocialMedia = EventData[trackerID]?.socialMedia;

    if (data) {
      tracker({
        trackerType: 'all',
        eventName: data.name,
        eventLabel: data.label,
        eventAction: data.action,
        data: { Course: courseName, Category: categoryName, SocialMedia },
      });
    }
  };

  // Replacing all "data-tracker-id" elements with the same, so no duplicate events gets created
  windowObj?.document?.querySelectorAll('[data-tracker-id]')?.forEach((ele) => {
    ele.outerHTML = ele.outerHTML;
  });

  // Adding click events for elements
  windowObj?.document?.querySelectorAll('[data-tracker-id]')?.forEach((ele) => {
    ele.addEventListener('click', addClick);
  });
};

export default ClickEvent;
