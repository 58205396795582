import { GlobalStyles } from 'twin.macro';
import 'tailwindcss/tailwind.css';
import '../styles/global.css';
import '../styles/swiper.min.css';
import axios from 'axios';
import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect } from 'react';
import * as gtag from '../lib/events/gtag';
import Script from 'next/script';
import 'intersection-observer';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import ToastifyContainer from 'components/layout-a/forms/common/ToastifyContainer';
import 'react-toastify/dist/ReactToastify.min.css';
import clickEvent from '../lib/events/clickEvent';
import { tracker } from '../lib/events/tracker';
import { stripTags } from '../lib/common/common';
import zopimChat from '../lib/events/zopimChat';
import LazyLoadScripts from '../components/lazyLoadScripts';
import triggerMoengageCourseView from '../lib/events/triggerMoengageCourseView';

declare const window: any;

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API;

// formsContext
export const FormsContext = createContext(null);
export const CommonContext = createContext(null);
export const GlobalContext = createContext<{
  courseName: any;
  categoryName: any;
  countryId: any;
  courseId: any;
  showRHS: any;
  setShowRHS: any;
  isLoadSiginButton: any;
  setIsLoadSigninButton: any;
} | null>(null);

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  let [loadScripts, setLoadScripts] = useState(false);

  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    document.addEventListener('scroll', changeLoadScripts);
    document.addEventListener('mousedown', changeLoadScripts);
    document.addEventListener('mousemove', changeLoadScripts);
    document.addEventListener('touchstart', changeLoadScripts);
    document.addEventListener('keydown', changeLoadScripts);

    function changeLoadScripts() {
      // setState
      setLoadScripts(true);

      // triggerMoengageCourseView
      triggerMoengageCourseView({
        coureName: pageProps?.course?.name || pageProps?.page?.name || '',
        categoryName: pageProps?.course?.category?.name || '',
      });

      document.removeEventListener('scroll', changeLoadScripts);
      document.removeEventListener('mousedown', changeLoadScripts);
      document.removeEventListener('mousemove', changeLoadScripts);
      document.removeEventListener('touchstart', changeLoadScripts);
      document.removeEventListener('keydown', changeLoadScripts);
    }
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };

    // Click Event
    window.addEventListener('load', () => {
      clickEvent({
        windowObj: window,
        courseName: pageProps?.course?.name || pageProps?.page?.name || '',
        categoryName: pageProps?.course?.category?.name,
      });
    });

    zopimChat();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const [isShoWForm, setIsShowForm] = useState(false);
  const [formName, setFormName] = useState('');

  const [moengageName, setMoengageName] = useState('');
  const [ga4EventKey, setGa4EventKey] = useState('');

  const [courseName, setCourseName] = useState(pageProps?.course?.name);
  const [categoryName, setCategoryName] = useState(pageProps?.course?.category?.name);
  const [countryId, setCountryId] = useState(pageProps?.bannerSection?.countryId);
  const [courseId, setCourseId] = useState(pageProps?.course?.id);
  const [showRHS, setShowRHS] = useState(false);
  const [isLoadSiginButton, setIsLoadSigninButton] = useState(true);

  // Store course data
  if (typeof window !== 'undefined' && courseName != '')
    localStorage.setItem(
      'courseData',
      JSON.stringify({ courseName: courseName || '', categoryName: categoryName || '' })
    );

  return (
    <>
      <GlobalContext.Provider
        value={{
          courseName,
          categoryName,
          countryId,
          courseId,
          showRHS,
          setShowRHS,
          isLoadSiginButton,
          setIsLoadSigninButton,
        }}
      >
        {pageProps?.pageName != 'MasterClass' && <Header isShowTopBanner={pageProps?.isShowTopBanner || false} topBannerData={pageProps?.topBannerData} phoneNumber={pageProps?.phoneData?.phone} />} 
        <ToastifyContainer>
          <ToastContainer />
        </ToastifyContainer>
        <GlobalStyles />
        <FormsContext.Provider
          value={[formName, setFormName, moengageName, setMoengageName, ga4EventKey, setGa4EventKey]}
        >
          <Component {...pageProps} />

          {pageProps?.tenant != 'upgrad' && (
            <Footer
              isDisplayFooterStick={pageProps?.pageName != 'MasterClass'}
              layoutCode={pageProps?.layoutCode || ''}
              isWhen1SectionExist={pageProps?.isWhen1SectionExist || false}
            />
          )}
        </FormsContext.Provider>
      </GlobalContext.Provider>
      

      {/* {Load Scripts} */}
      {loadScripts && <LazyLoadScripts pageName={pageProps?.pageName != 'MasterClass' ? '' : 'MasterClass'} />}

      <Script
        strategy="lazyOnload"
        src="https://infinitybundles.knowledgehut.com/exit-intent-popup/0.0.17/public/main.js"
      ></Script>
    </>
  );
}

export default MyApp;
